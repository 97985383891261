.callerWrapper {
  z-index: 1;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px 16px 20px;
  box-shadow: 0 18.5px 14px -4px rgba(0, 0, 0, 0.3);
}

.callerInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 14px;
  padding-bottom: 18px;
}

.callerPhone {
  font-weight: 400;
  font-size: 16px;
  color: #101010;
}

.callerName {
  font-weight: 700;
  font-size: 16px;
  color: #101010;
  margin: 5px 0;
}

.callerId {
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  color: #101010;
}

.timerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 8px;
  }
}

.signatureWrapper {
  display: flex;
  align-items: center;
}

.signatureData {
  font-weight: 400;
  font-size: 12px;
  color: #101010;
  opacity: 0.7;
  max-width: 159px;
  margin-left: 13px;
}

.callerUpdate {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.styled {
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  max-width: 90px;
  height: max-content;
  color: #101010;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;

  img {
    margin-right: 7px;
  }
}

.lineWrapper {
  position: relative;
  display: flex;
  width: max-content;
}

.lineTwo {
  animation: play 2s 1s ease-in-out infinite;
}

.lineOne {
  animation: play 2s 2s ease-in-out infinite;
}

@keyframes play {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
