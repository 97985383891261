.button {
    width: 100%;
    height: 50px;
    background: #101010;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 10px;
    box-sizing: border-box;
}

.isDisabled {
    background: #f4f4f4;
    border: 1px solid #f4f4f4;
    pointer-events: none;
    color: #9F9F9F;
}
