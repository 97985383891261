 .wavesWrapper {
   position: absolute;
   width: 100%;
   bottom: calc(100vw - 111vw);
 }

 .callTitle {
   font-weight: 400;
   font-size: 12px;
   line-height: 15px;
   color: #ffffff;
   margin-bottom: 10px;
 }

 .phoneTittle {
   font-weight: 400;
   font-size: 24px;
   line-height: 29px;
   letter-spacing: 0.05em;
   color: #ffffff;
 }

 .callWrapper {
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 8px 0 100px 0;
   margin-bottom: 40px;
   background-color: #101010;
 }

 .contentRender {
   display: flex;
   justify-content: space-between;
 }

 .speechContent {
   display: flex;
   flex-direction: column;
   align-items: center;
 }

  .phone {
    position: absolute;
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

 .colorRoundFirst,
 .colorRoundSecond,
 .colorRoundThird,
 .blackRound {
   position: absolute;
   top: 50%;
   left: 50%;

   display: flex;
   justify-content: center;
   align-items: center;

   transform: translate(-50%, -50%);
   border-radius: 100%;
 }

 .wrapper {
   position: relative;
   width: 140px;
   height: 140px;
   margin-bottom: 17px;
 }

 .colorRoundThird {
   width: 92px;
   height: 92px;
   background: linear-gradient(
     121.43deg,
     rgba(136, 38, 255, 0.9) 13.61%,
     rgba(0, 240, 255, 0.9) 97.94%
   );

   animation: ringing infinite 2s linear;
 }

 .colorRoundSecond {
   width: 120px;
   height: 120px;

   background: linear-gradient(
     121.43deg,
     rgba(136, 38, 255, 0.4) 13.61%,
     rgba(0, 240, 255, 0.4) 97.94%
   );

   animation: ringing infinite 2s linear;
 }

 .colorRoundFirst {
   width: 140px;
   height: 140px;

   background: linear-gradient(
     121.43deg,
     rgba(136, 38, 255, 0.2) 13.61%,
     rgba(0, 240, 255, 0.2) 97.94%
   );
   animation: ringing infinite 2s linear;
 }

 .blackRound {
   width: 66px;
   height: 66px;
   background: #101010;
 }

 @keyframes ringing {
   0% {
     transform: translate(-50%, -50%) scale(0.5);
   }
   100% {
     transform: translate(-50%, -50%) scale(1);
   }
 }
