.mainWrapper {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 32px;
  text-align: center;
  font-weight: 700;
  color: #29323d;
}

.logoTitle {
  font-size: 32px;
  text-align: center;
  font-weight: 400;
  color: #29323d;
  margin-top: 0;
  margin-bottom: 50px;
}

.titleUser {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #101010;
}

.titleCompany {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #858691;
  text-transform: capitalize;
}

.companyWrapper {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 8px 0 27px 0;
}

.company {
  display: flex;
}

.logo {
  width: 150px;
  height: 120px;
}

.description {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
}
