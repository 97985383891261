.resultFaceWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1240px;
}

.speechWrapper {
  width: calc(100% - 50px);
  min-height: 260px;
  padding: 25px;
  display: flex;
  justify-content: flex-start;
}

.speechContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.speechTitle {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-top: 37px;
}

.wr {
  border-left: 1px solid #e6e6e6;
  margin: 0 10px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noFraudNoImage {
  display: flex;
  flex-direction: row;
}

.noFraudWithImage {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.spacing {
  padding: 0 16px 20px 16px;
}

.generatedAgeGenderText {
  padding: 25px 34px;
}

.contentTextWrapper {
  display: flex;
  gap: 23px;
  margin-bottom: 8px;
}

.titleWrapper {
  width: 60px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5c5f65;
}

.listWrapper {
  max-width: 150px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.titleContent {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101010;
}

.titleContentList {
  width: max-content;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #212020;
  margin-bottom: 2px;
  text-transform: capitalize;
  opacity: 0.9;
  border-radius: 32px;
  padding: 6px 12px;
  border: 1px solid var(--neutral-2, #F2F2F2);
}
.clientName {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #101010;
}

.actionWrapper {
  width: 100%;
  display: flex;
  box-shadow: 0 -1.5px 14px rgba(0, 0, 0, 0.25);
}

.actionContainer {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px;
  border: 1px solid #d0d0d0;
  cursor: pointer;
}

.restartTitle {
  font-weight: 400;
  font-size: 12px;
  color: #101010;
  text-align: center;
  margin-top: 8px;
}

.additionalLoader {
  margin-right: 9px;
  width: 93px;
  height: 93px;
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.imageContainer {
  display: flex;
}
