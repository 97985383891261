.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
}

.contentWrapper {
  position: relative;
  max-width: 100vw;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: #101010;
  z-index: 10;
}

.headerContent {
  max-width: 1240px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  height: 13px;
  padding: 9px 0;
}

.infoContent {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #b7b7b7;
}

.logo {
  width: 100px;
  height: 100px;
  left: 10px;
  top: 0;
  cursor: pointer;
}

.menu {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.microphone {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
}

.btnLog {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  padding: 5px;
  color: #1f2335;
  margin-top: 5px;
  cursor: pointer;
  background-color: #f4f7ff;
  border-radius: 3px;
  border: 1px solid #1f2335;
}

.main {
  width: 100%;
  padding-top: 45px;
  background: #ffffff;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: calc(100vh - 102px);
}

.footer {
  background: #ffffff;
  height: 36px;
  width: 100%;
}
