.wrapper {
  width: 100%;
  padding-top: 113px;
  padding-left: 35px;
}

.subWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #737373;

    margin-bottom: 5px;
  }

  .id {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #101010;
  }

  .name {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    color: #101010;
  }
}

.signatureWrapper {
  display: flex;
  align-items: center;
}

.signatureData {
  font-weight: 400;
  font-size: 12px;
  color: #363636;
  margin-left: 13px;
}
